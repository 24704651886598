import React, { useEffect, useState } from 'react'
import { useRecoilState } from "recoil";
import { openCampaignDialogBoxState, allChatsState, broadcastIdState, chatsNextURLState, chatTypeState, msgStatusState, appliedFilterCountState } from "../../store/agent-chats";
import axiosInstance from '../../plugins/axios';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

const ChatCampaignDialog = ({handle_by}) => {
    const [openCampaignDialogBox, setOpenCampaignDialogBox] = useRecoilState(openCampaignDialogBoxState);
    const [broadcastList, setBroadcastList] = useState([]);
    const [broadcastId, setBroadcastId] = useRecoilState(broadcastIdState);
    const [ allChat, setAllChats ] = useRecoilState(allChatsState);
    const [ chatsNextURL, setChatsNextURL ] = useRecoilState(chatsNextURLState);
    const [ chatType, setChatType ] = useRecoilState(chatTypeState);
    const [ msgStatus, setMsgStatus ] = useRecoilState(msgStatusState);
    const [ appliedFilterCount, setAppliedFilterCount ] = useRecoilState(appliedFilterCountState);
    const [ loading, setLoading] = useState(false)


    useEffect( async () => {
        try {
            await fetchData();
        } catch (error) {
            console.log(error);
        }
    }, [])

    const fetchData = async () => {
        try {
            const res = await axiosInstance.get('/api/v1/communications/whatsapp/campaigns/');
            setBroadcastList(res?.data?.results);
        } catch (error) {
            console.log(error);
        }
    }

    const handleClose = () => setOpenCampaignDialogBox(false);

    const handleSearch = async () =>{
        setLoading(true)
        if(broadcastId && broadcastId?.id){
            const response = await axiosInstance.get(`/api/v1/communications/whatsapp/all-chats/?being_handled_by=${chatType}&is_new_whatsapp_message=${msgStatus}&campaign_id=${broadcastId.id}`);
            setChatsNextURL(response.data.next);
            setAllChats(response.data.results);
            setChatsNextURL(response.data.next)
            setOpenCampaignDialogBox(false);
            setLoading(false)
        }else{
            const response = await axiosInstance.get(`/api/v1/communications/whatsapp/all-chats/?being_handled_by=${chatType}&is_new_whatsapp_message=${msgStatus}`);
            setChatsNextURL(response.data.next);
            setAllChats(response.data.results);
            setChatsNextURL(response.data.next)
            setOpenCampaignDialogBox(false);
            setLoading(false)
        }

        setAppliedFilterCount((prevState) => ({
            ...prevState, 
            msgStatus: msgStatus === 'all' ? 0 : 1,
            chatType: chatType === 'all' ? 0 : 1,
            broadcast: broadcastId ? 1 : 0,
        }))
    }

    const handleBroadcastId = (value) => {
        console.log(value)
        setBroadcastId(value);
    }

    const handleBroadcastChange = async (e) => {
        const { data } = await axiosInstance.get(`/api/v1/communications/whatsapp/campaigns/?name=${e.target.value}`)
        setBroadcastList(data?.results);
    }

    const handleChatTypes = async (e) => {
        setChatType(e.target.value);
    };

    const handleMsgStatus = async (e) => {
        setMsgStatus(e.target.value);
    };

    return (
        <>
            <Dialog
                open={openCampaignDialogBox}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Apply Filters</DialogTitle>
                <DialogContent sx={{display: 'flex'}}>
                    <FormControl sx={{ m: 1, width: 200 }} size="small">
                        <Autocomplete
                            id="chat-campaign-template"
                            options={broadcastList.map((option, index) => ({ ...option, key: index }))}
                            autoHighlight
                            getOptionLabel={(option) => option.name}
                            size='small'
                            value={broadcastId || null}
                            onChange={(event, newValue) => handleBroadcastId(newValue)}
                            renderInput={(params) => (
                                <TextField onChange={(e) => handleBroadcastChange(e)} {...params} label="Broadcast List" />
                            )}
                        />
                    </FormControl>
                    <FormControl sx={{ m: 1, width: 200 }} size="small">
                        <TextField
                            id="outlined-select-currency"
                            select
                            label="Handled By"
                            size='small'
                            defaultValue="all"
                            value={chatType}
                            onChange={(e) => handleChatTypes(e)}
                            >
                                <MenuItem value="all">
                                    All
                                </MenuItem>
                                <MenuItem value="bot">
                                    Bot
                                </MenuItem>
                                <MenuItem value="counsellor">
                                    Agent
                                </MenuItem>
                            </TextField>
                    </FormControl>
                    <FormControl sx={{ m: 1, width: 200}} size="small">
                        <TextField
                            id="outlined-select-currency"
                            select
                            label="Message Status"
                            size='small'
                            defaultValue="all"
                            value={msgStatus}
                            onChange={(e) => handleMsgStatus(e)}
                            >
                                <MenuItem value="all">
                                    All
                                </MenuItem>
                                <MenuItem value="false">
                                    Read
                                </MenuItem>
                                <MenuItem value="true">
                                    Unread
                                </MenuItem>
                            </TextField>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSearch} disabled={loading} autoFocus>
                        Apply
                    {loading && (
                        <CircularProgress
                            color="success"
                            size={24}
                            sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                            }}
                        />
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ChatCampaignDialog