import React, { useState, useRef, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import { openCreateBroadcastBoxState } from '../../store/broadcasts';
import { AlertOpenState } from '../../store/journey-list';
import { useRecoilState } from 'recoil';
import styles from '../../assets/scss/page/broadcasts.module.scss';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import AlertDialog from '../../components/basic-components/alert-dialog';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CancelIcon from '@mui/icons-material/Cancel';
import SendIcon from '@mui/icons-material/Send';
import FormHelperText from '@mui/material/FormHelperText';
import DownloadIcon from '@mui/icons-material/Download';
import SampleCSVFile from '../../assets/files/broadcastSample.csv';
import Tooltip from '@mui/material/Tooltip';
import axiosInstance from '../../plugins/axios';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Autocomplete from '@mui/material/Autocomplete';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DigitalClock } from '@mui/x-date-pickers/DigitalClock';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InputLabel from '@mui/material/InputLabel';
import ClearIcon from '@mui/icons-material/Clear';
import moment from 'moment';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const ClearIconButton = styled(IconButton)(({ theme }) => ({
    '&:hover': {
      backgroundColor: 'transparent',
    },
}));

const ErrorText = styled('p')`
  color: red;
  font-size: 0.75rem;
  margin-bottom: 0px;
  margin-top: 2px;
`;

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const TestBroadcastSwitch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
      borderRadius: 22 / 2,
      '&::before, &::after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 16,
        height: 16,
      },
      '&::before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      '&::after': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      width: 16,
      height: 16,
      margin: 2,
    },
}));

const allowedTimes = [
    { hours: 9, minutes: 0 },
    { hours: 10, minutes: 0 },
    { hours: 11, minutes: 0 },
    { hours: 12, minutes: 0 },
    { hours: 14, minutes: 0 },
    { hours: 17, minutes: 30 },
    { hours: 18, minutes: 0 },
    { hours: 19, minutes: 0 },
    { hours: 20, minutes: 0 },
    { hours: 20, minutes: 30 },
];



const disablePast = (date) => {
    return moment().isAfter(date) ? true : false;
}

function MyFormHelperText() {
    const { focused } = useFormControl() || {};
  
    const helperText = React.useMemo(() => {
      if (focused) {
        return 'This field is being focused';
      }
  
      return 'Helper text';
    }, [focused]);

    return <FormHelperText>{helperText}</FormHelperText>;
}

const CreateBroadcasts = () => {
    const [openDialog, setOpenDialog] = useRecoilState(openCreateBroadcastBoxState);
    const [templateId, setTemplateId] = useState('');
    const [broadcastName, setBroadcastName] = useState('');
    const [broadcastNameBlur, setBroadcastNameBlur] = useState(false);
    const [fileName, setFileName] = useState('');
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [alertOpen, setAlertOpen] = useRecoilState(AlertOpenState);
    const [isTestBroadcast, setIsTestBroadcast] = useState(false);
    const [templateList, setTemplateList] = useState([]);
    const [scheduledDate, setScheduledDate] = useState(null);
    const [scheduledTime, setScheduledTime] = useState('');
    const [templateIdBlur, setTemplateIdBlur] = useState(false);
    
    const oneWeekFromNow = moment().add(7, 'days');

    const theme = useTheme();
    const inputRef = useRef(null);

    useEffect(async () => {
        try{
            const {data} = await axiosInstance.get(`/api/v1/communications/whatsapp/campaigns/templates/list/?&is_hidden=false`);
            setTemplateList(data.results);
        }catch(error){
            console.log(error)
        }
    }, []);

    const handleClose = () => {
        setOpenDialog(false)
    }

    const submitData = async (e) => {
        try {
            e.preventDefault();
            if (broadcastName === '') {
                setBroadcastNameBlur(true);
                return
            }
            if (templateId === '' || !templateId) {
                setTemplateIdBlur(true);
                return
            }

            if(file === null){
                setAlertOpen({ ...alertOpen, open: true, msgType: 'error', alertMsg: 'Please upload a file.' });
                return
            }
            setLoading(true);
            const formData = new FormData();
            formData.append('file', file);
            formData.append('file1', file);
            formData.append('type', !isTestBroadcast ? 'test' : 'production');
            formData.append('name', broadcastName);
            formData.append('template_id', templateId?.id);
            if(scheduledDate){
                let inputDate = `${moment(scheduledDate).format('YYYY-MM-DD')} ${scheduledTime}`
                inputDate = moment(inputDate).format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
                formData.append('schedule_time', inputDate);
            }
            const res = await axiosInstance.post('/api/v1/communications/whatsapp/campaigns/bulk-shoot/', formData);
            if (res.status === 200) {
                if(!isTestBroadcast){
                    setLoading(false);
                    setAlertOpen({ ...alertOpen, open: true, msgType: 'success', alertMsg: `${res?.data?.result ? res?.data?.result : 'Broadcast test successfully.'}` });
                    return
                }else{
                    setLoading(false);
                    setAlertOpen({ ...alertOpen, open: true, msgType: 'success', alertMsg: `${res?.data?.result ? res?.data?.result : 'Broadcast created successfully.'}` });
                    if(isTestBroadcast){
                        setOpenDialog({ ...openDialog, open: false });
                        window.location.reload();
                    }
                }
            } else {
                setLoading(false);
                setAlertOpen({ ...alertOpen, open: true, msgType: 'error', alertMsg: `${res?.data?.result ? res?.data?.result : 'Failed to create broadcast. Please try again.'}` });
            }
        } catch (error) {
            setLoading(false);
            setAlertOpen({ ...alertOpen, open: true, msgType: 'error', alertMsg: `${error?.response?.data?.result ? error?.response?.data?.result : 'Failed to create broadcast. Please try again.'}` });
            console.error('Error fetching data:', error);
        }
    };
    
    
    const onChangeFileHandler = async (e) => {
        try{
            const file = e.target.files[0];
            setFileName(file.name);
            setFile(file);
            // await uploadMedia(file);
        }catch (error) {
            
        }
    }

    const validateOptInName = () => {
        if(broadcastName === ''){
            return 'Broadcast name is required';
        }else if(broadcastName.length > 200){
            return 'Broadcast name must be less than 200 characters';
        }   
        return ''; 
    }

    const downloadSampleFile = () => {
        const link = document.createElement('a');
        link.href = SampleCSVFile; // Set the href attribute to the URL of your sample CSV file
        link.download = 'sample_file.csv'; // Set the download attribute to specify the file name
        // Append the link to the document body
        document.body.appendChild(link);
        // Trigger the click event on the link to start the download
        link.click();
        // Remove the link from the document body
        document.body.removeChild(link);
    }

    const resetFileData = () => {
        // setFileName('');
        setFileName('');
        setFile(null);
    }

    const handleTemplateChange = async (e) => {
        try {
            const {data} = await axiosInstance.get(`/api/v1/communications/whatsapp/campaigns/templates/list/?name=${e.target.value}&is_hidden=false`);
            setTemplateList(data.results);
        }
        catch(error){
            console.log(error)
        }
    }

    const templateIdError = () => {
        if(templateId === '' || !templateId){
            return 'Template is required';
        }
        return '';
    }

    const handleClearDate = () => {
        setScheduledDate(null);
        setScheduledTime('');
    };

    const handleSetBroadcastType = (e) => {
        setIsTestBroadcast(e.target.checked);
        setScheduledDate(null);
    }

    const setDate = (date) => {
        setScheduledDate(date);
        setScheduledTime('');
    }

    const setTime = (event) => {
        setScheduledTime(event.target.value);
    }


    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth='sm'
                open={openDialog.open}
                onClose={handleClose}
                style={{ backdropFilter: 'blur(5px)' }}
            > 
                <div className={styles.dialog__container}>
                    <Stack direction="row" spacing={2} className='mt-1 d-flex justify-content-between align-items-center'>
                        <h4>{openDialog.title}</h4>
                        <div className="">
                            <IconButton onClick={handleClose}>
                                <CloseOutlinedIcon 
                                    className={styles.close__btn} 
                                /> 
                            </IconButton>
                        </div>
                    </Stack>
                    <div>
                        <form noValidate autoComplete="off" onSubmit={submitData} className='mt-4 w-100'>
                            <Stack direction="row" spacing={2} >
                                <div className="w-50">
                                    <FormControl fullWidth>
                                        <TextField id="broadcast-name" 
                                            label="Broadcast Name" 
                                            style={{ color: theme.palette.primary.dark }} 
                                            value={broadcastName} 
                                            onChange={(e) => setBroadcastName(e.target.value)} 
                                            size='small' 
                                            onBlur={() => setBroadcastNameBlur(true)}
                                            error={broadcastNameBlur && validateOptInName() !== ''}
                                        />
                                        {
                                            broadcastNameBlur && validateOptInName() !== '' ? 
                                            <ErrorText>{validateOptInName()}</ErrorText> : 
                                            <FormHelperText id="component-error-text" style={{ marginLeft: '4px' }}>Note: Broadcast request name must be less than 200 characters.</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                                <div className="w-50">
                                    <FormControl fullWidth>
                                            {!fileName ? <Button
                                                component="label"
                                                role={undefined}
                                                variant="contained"
                                                tabIndex={-1}
                                                startIcon={<CloudUploadIcon />}
                                                disabled={loading}
                                                >
                                                Upload CSV File
                                                <VisuallyHiddenInput 
                                                    type="file" 
                                                    accept="text/csv" 
                                                    onChange={onChangeFileHandler} 
                                                    ref={inputRef}  
                                                />
                                            </Button> : 
                                            <div className='d-flex align-items-center justify-content-between' style={{backgroundColor: theme.palette.primary.light, color: theme.palette.primary.dark, padding: '4px 8px', borderRadius: '20px', marginLeft: '4px'}}>
                                                <div className="">
                                                    {fileName}
                                                </div>
                                                <div className="">
                                                    <Tooltip title="Remove file">
                                                        <IconButton size='small' onClick={resetFileData} style={{marginLeft: '2px', cursor: 'pointer'}}>
                                                            <CancelIcon style={{color: theme.palette.primary.dark}} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                            </div>}
                                    </FormControl>
                                </div>
                            </Stack>
                            <Stack direction="row" spacing={2} >
                                <div className="w-50 d-flex align-items-center">
                                    <FormControl>
                                        <FormControlLabel
                                            control={<TestBroadcastSwitch defaultChecked={isTestBroadcast} onChange={(e) => handleSetBroadcastType(e)} />}
                                            label="Production"
                                            labelPlacement='end'
                                        />
                                    </FormControl>
                                </div>
                                <div className="w-50 d-flex align-items-center">
                                    <FormControl fullWidth>
                                        <Autocomplete 
                                            id="combo-box-demo"
                                            options={templateList}
                                            getOptionLabel={(option) => option.name} // Use the name property as the display value
                                            size='small'
                                            value={templateId || null}
                                            onChange={(event, newValue) =>  setTemplateId(newValue)}
                                            renderInput={(params) => <TextField  onChange={(e) => handleTemplateChange(e)}{...params} label="Template List"
                                                error={ templateIdBlur && templateIdError() !== ''}
                                                onBlur={() => setTemplateIdBlur(true)}
                                            />}
                                            renderOption={(props, option) => (
                                                <Tooltip title={option.name || ''} arrow placement="right">
                                                    <li {...props}>{option.name}</li>
                                                </Tooltip>
                                            )}
                                        />
                                        { templateIdBlur && templateIdError() !== '' && <ErrorText>{templateIdError()}</ErrorText>}
                                    </FormControl>
                                </div>
                            </Stack>
                            {isTestBroadcast && <Stack direction="row" spacing={2} >
                                <div className="w-50 mt-2">
                                    <InputLabel id="demo-simple-select-label" className='pb-1' style={{ paddingLeft: '4px' }}>Schedule Date (Optional)</InputLabel>
                                    <FormControl fullWidth size="small">
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <Stack direction="row" alignItems="center">
                                            <DatePicker
                                                value={scheduledDate}
                                                onChange={(newValue) => setDate(newValue)}
                                                sx={{ '& .MuiInputBase-input': { padding: '8px' } }}
                                                disablePast={disablePast}
                                            />
                                            {scheduledDate && (
                                                <ClearIconButton onClick={handleClearDate} size="small">
                                                    <ClearIcon />
                                                </ClearIconButton>
                                            )}
                                            </Stack>
                                        </LocalizationProvider>
                                    </FormControl>
                                </div>
                                <div className="w-50 mt-4">
                                    <FormControl variant="standard" fullWidth>
                                        <InputLabel id="time_broadcast">Time</InputLabel>
                                        <Select
                                            id="scheduled-time"
                                            value={scheduledTime}
                                            onChange={(e) => setScheduledTime(e.target.value)}
                                            fullWidth
                                            disabled={!scheduledDate}
                                            disableUnderline
                                        >
                                            <MenuItem value="">Select Time</MenuItem>
                                            {allowedTimes.map(time => (
                                                <MenuItem
                                                    key={`${time.hours}:${time.minutes}`}
                                                    value={`${time.hours}:${time.minutes}`}
                                                    disabled={moment().isSame(scheduledDate, 'day') && moment().add(15, 'minutes').isAfter(moment(scheduledDate).set({ hour: time.hours, minute: time.minutes }))}
                                                >
                                                    {moment().set({ hour: time.hours, minute: time.minutes }).format('hh:mm A')}
                                                </MenuItem>
                                            ))}
                                        </Select>

                                    </FormControl>
                                </div>
                            </Stack>}
                            <div id="component-error-text" style={{ marginLeft: '4px' }}>
                                <Button onClick={downloadSampleFile} variant="outlined" color="primary" startIcon={<DownloadIcon />} className='mt-3'>Download Sample File</Button>
                                <div className="" style={{ fontSize: '14px', color: theme.palette.primary.dark }}>
                                    <ol className={styles.sample__list}>
                                        <li>"number" and "country_code" columns are mandatory and no ‘+’ symbol. Eg. "91" not "+91"</li>
                                        <li>Phone number should not have country code as prefix.</li>
                                        <li>Only CSV format is supported</li>
                                        <li>Include a maximum of 5000 numbers in the sheet. If you are sending a test campaign, include a maximum of 10 numbers in the sheet.</li>
                                    </ol>
                                </div>
                            </div>
                            <Stack direction="row" spacing={2} className='mt-3 d-flex justify-content-end align-items-center'>
                                <Button variant="outlined" onClick={() => setOpenDialog({open: false, type: '', title: '', id: ''})} className='d-flex justify-content-center align-items-center' endIcon={<CancelIcon className='mb-1' />}>
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    disabled={ loading ||
                                        (scheduledDate && !scheduledTime) ||
                                        (moment().isSame(scheduledDate, 'day') &&
                                        moment()
                                            .add(15, 'minutes')
                                            .isAfter(
                                            moment(scheduledDate).set({
                                                hour: parseInt(scheduledTime.split(':')[0]),
                                                minute: parseInt(scheduledTime.split(':')[1]),
                                            })
                                            ))
                                    }
                                    type="submit"
                                    endIcon={<SendIcon />}
                                    >
                                    {loading && <div className="spinner-border text-primary mx-2" style={{width: '16px', height: '16px'}} role="status"></div>}
                                    {openDialog.type === 'edit' && openDialog.id ? 'Update' : scheduledDate ? 'Schedule' : 'Send Now'}
                                </Button>
                            </Stack>
                        </form>
                    </div>   
                </div>
            </Dialog>
            <AlertDialog />   
        </>
    )
}

export default CreateBroadcasts;
